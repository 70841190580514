<template>
<form @submit.prevent="">
    <div class="form-row">
        <v-text-field v-if="isMobile" class="mobile-app-input mobile-app-input-modal" label="عنوان پوشه" v-model="newCategory"></v-text-field>
        <div class="col-12 col-md-12" v-if="!isMobile">
            <input type="text" placeholder="عنوان پوشه" class="widget-input input-no-bg input-lg input-set-border  w-100" v-model="newCategory">
        </div>
    </div>
    <div class="form-row text-center mt-4">
        <div class="col-12">
            <a href="" @click.prevent="saveCategory()" :class="'min-w150 tgju-btn tgju-btn-lg tgju-btn-primary ' + (addEditLoading ? 'loading-active inline-flex' : '')">
                <i class="uil uil-folder-plus"></i>
                <span class="span-title">ایجاد پوشه</span>
            </a>
        </div>
    </div>
</form>
</template>

<style lang="scss" scoped>
.cancel-edit-btn {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    color: #1d1c1c;
}
</style>

<script>
// این کامپوننت مودال جهت ایجاد یک دسته بندی یا پوشه جدید برای ذخیره سازی اسناد و یاداشت ها استفاده میشود
export default {
    name: 'NoteCategoriesListModal',
    props: ['data'],
    components: {},
    data: function () {
        return {
            newCategory: '',
            editMode: false,
            editId: null,
            addEditLoading: false,
            isMobile:false,
        }
    },
    mounted() {
        if (Vue.prototype.$helpers.checkDevice('app')) {
            this.isMobile= true;
        }
    },
    methods: {
        saveCategory() {
            if (this.addEditLoading == false) {
                this.addCategory();
            }
        },
        // این متد وظیفه ارسال درخواست به سرور جهت ذخیره سازی دسته بندی یا پوشه جدید را دارد
        addCategory() {
            if (!this.newCategory) {
                return;
            }

            let api_data = {
                title: this.newCategory,
                extension: this.data.widget.type,
            };
            this.addEditLoading = true;

            this.$helpers.makeRequest('POST', '/category/store', api_data).then(api_response => {
                this.$helpers.api_alert(api_response);

                if (api_response.status == 200) {
                    let category = api_response.data.response.category;

                    this.$parent.emitData('addCategory', { category: this.$helpers.unbindObject(category) });
                    this.newCategory = '';

                    this.$parent.closeModal();
                    this.$swal.fire({
                        icon: "success",
                        title: "پوشه با موفقیت ایجاد شد",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                } else {
                    this.$swal.fire({
                        icon: "error",
                        title: "مشکلی در ایجاد پوشه رخ داده است",
                        showConfirmButton: !1,
                        timer: 2000
                    });
                    this.addEditLoading = false;
                }
            });
        },
    },
}
</script>
